// Footer.jsx

import React from 'react';
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="column-footer">
      </div>
      <div className="column-footer">
        <a href="https://github.com/mariasuevos" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-github"></i>
        </a>
      </div>
      <div className="column-footer">
        <a href="https://www.linkedin.com/in/maria-suevos-chinchilla/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
      <div className="column-footer">
        <a href="tel:+34629921840">
          <i className="fas fa-phone"></i>
        </a>
      </div>
      <div className="column-footer">
        <a href="mailto:mariasuevosc@gmail.com">
          <i className="far fa-envelope"></i>
        </a>
      </div>
      <div className="column-footer">
      </div>
    </footer>
  );
};

export default Footer;
