// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  display: flex;
  flex-direction: column;
  margin: 0;
}

#root {
  flex: 1 1;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #e6e4e2;
  /*#F6F5EF; */
  color: #9c987f;
  padding: 10px;
}

[data-theme='dark'] .footer {
  background-color: rgb(16, 16, 22);
  color: rgb(45, 184, 154);
}


.column-footer {
  text-align: center;
  margin: 0px 10px;
}

a {
  color: rgb(45, 184, 154);
  text-decoration: none;
  font-size: 20px;
}

.fa-github:before {
  content: '\\f09b';
}

.fa-linkedin:before {
  content: '\\f08c';
}

.fa-phone:before {
  content: '\\f095';
}

.fa-envelope:before {
  content: '\\f0e0';
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,cAAc;EACd,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,wBAAwB;AAC1B;;;AAGA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["body {\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n}\n\n#root {\n  flex: 1;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  background-color: #e6e4e2;\n  /*#F6F5EF; */\n  color: #9c987f;\n  padding: 10px;\n}\n\n[data-theme='dark'] .footer {\n  background-color: rgb(16, 16, 22);\n  color: rgb(45, 184, 154);\n}\n\n\n.column-footer {\n  text-align: center;\n  margin: 0px 10px;\n}\n\na {\n  color: rgb(45, 184, 154);\n  text-decoration: none;\n  font-size: 20px;\n}\n\n.fa-github:before {\n  content: '\\f09b';\n}\n\n.fa-linkedin:before {\n  content: '\\f08c';\n}\n\n.fa-phone:before {\n  content: '\\f095';\n}\n\n.fa-envelope:before {\n  content: '\\f0e0';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
