import React from 'react';
import "./UnderConstruction.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';

const UnderConstructionPage = () => {
  return (
    <div className='textContainer'>
      <h1>Oops! This page is still under construction.</h1>
      <p> Please check back later.</p>
      <FontAwesomeIcon icon={faTools} className='toolIcon' />
    </div>
  );
};

export default UnderConstructionPage;
