import React from 'react';
import './Loading.css';

const Loading = () => {

  console.log('Loading');
  return (
    < div className="loading-container" >
      <div className="tennis-ball"></div>
      <p className="loading-text">Loading...</p>
    </div >
  );
};

export default Loading;
