const introdata = {
    title: "María Suevos",
    animated: {
        first: "I love coding",
        second: "I love Artificial Intelligence",
        third: "I love new challenges",
    },
    description1: "Hi! I'm an AI engineer and software developer based in Spain. I'm passionate about technology and I love to learn new things.",
    description2: "Want to know more about me? Check out my projects and my resume!",
};
export {introdata}
