import React, { useState } from 'react';
import './Buscador_torneo.css';
import { IoSearchCircleOutline } from "react-icons/io5";
import listaTorneos from "../db/json/tournaments_rounds.json";
const listaTorneosData = Object.keys(listaTorneos['listaTorneos']);

const Buscador_torneo = ({ onTorneoBuscado, onRondaSeleccionada }) => {
  const [busqueda, setBusqueda] = useState('');
  const [resultados, setResultados] = useState([]);
  const [nombreBuscado, setNombreBuscado] = useState('');
  const [sugerencias, setSugerencias] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [rondaSeleccionada, setRondaSeleccionada] = useState(null);

  const handleBusqueda = (event) => {
    const textoBusqueda = event.target.value;
    setBusqueda(textoBusqueda);

    const resultadosFiltrados = listaTorneosData.filter((torneo) =>
      torneo.toLowerCase().includes(textoBusqueda.toLowerCase())
    );

    setResultados(resultadosFiltrados);
    setSugerencias([]);
  };

  const handleClickNombre = (torneo) => {
    setBusqueda(torneo);
    setResultados([]);
  };

  const handleBuscarClick = () => {
    const torneoEncontrado = listaTorneosData.find(
      (torneo) => torneo.toLowerCase() === busqueda.toLowerCase()
    );

    if (torneoEncontrado) {
      setNombreBuscado(torneoEncontrado);
      setRondaSeleccionada(null);
      setSugerencias([]);
      onTorneoBuscado(torneoEncontrado);
    } else {
      setNombreBuscado('');
      const sugerenciasFiltradas = listaTorneosData.filter((torneo) =>
        torneo.toLowerCase().includes(busqueda.toLowerCase().slice(0, 2))
      );
      setSugerencias(sugerenciasFiltradas);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (!document.activeElement.classList.contains('buscador')) {
        setResultados([]);
        setSugerencias([]);
        setNombreBuscado('');
        setIsFocused(false);
      }
    }, 0);
  };

  const handleNombreClick = (nombre, event) => {
    event.stopPropagation();
    setBusqueda(nombre);
    setResultados([]);
    setSugerencias([]);
  };
  return (
    <div className="contenedor-buscador">
      <div
        className="buscador-torneo"
        onMouseEnter={() => setIsFocused(true)}
        onMouseLeave={() => setIsFocused(false)}
      >
        <input
          type="text"
          placeholder="Search tournament"
          value={busqueda}
          onChange={handleBusqueda}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <button onClick={handleBuscarClick}>
          <IoSearchCircleOutline className='button-pequeño' />
        </button>

        {isFocused && resultados.length > 0 && (
          <ul className='ul-buscador'>
            {resultados.map((torneo, index) => (
              <li key={index} onClick={() => handleClickNombre(torneo)} className="nombre-item">
                <div onMouseDown={(event) => handleNombreClick(torneo, event)}>
                  {torneo}
                </div>
              </li>
            ))}
          </ul>
        )}

        {sugerencias.length > 0 && (
          <ul className='ul-buscador'>
            <p>Maybe you meant?</p>
            {sugerencias.map((sugerencia, index) => (
              <li key={index} onClick={() => { setBusqueda(sugerencia); handleBuscarClick(); }} className="nombre-item">
                {sugerencia}
              </li>
            ))}
          </ul>
        )}
      </div>

      {nombreBuscado && (
        <div className="contenedor-rondas">
          <div className="contenedor-rondas-buttons" style={{ fontSize: '1.5em', marginTop: '10px' }}>
            <div>
              {listaTorneos['listaTorneos'][nombreBuscado].map((ronda, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setRondaSeleccionada(ronda);
                    onRondaSeleccionada(ronda);
                  }}
                  className={rondaSeleccionada === ronda ? 'ronda-seleccionada' : ''}
                >
                  {ronda}
                </button>
              ))}
            </div>
          </div>

          {resultados.length > 0 && (
            <div>
              <ul className='ul-buscador'>
                {resultados.map((torneo, index) => (
                  <li key={index}></li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );

};

export default Buscador_torneo;
