import React, { useState } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Resume from './Pages/Resume/Resume';
import Home from './Pages/Home/Home';
import Projects from './Pages/Projects/Projects';
import Tennis from './Pages/Projects/Tennis/Tennis';
import UnderConstructionPage from './Components/UnderConstruction/UnderConstruction';

function App() {
  const [currentPage, setCurrentPage] = useState('home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="App">
      <Navbar onPageChange={handlePageChange} />
      {currentPage === 'home' && <Home />}
      {currentPage === 'projects' && <Projects onPageChange={handlePageChange} />}
      {currentPage === 'resume' && <Resume />}
      {currentPage === 'tennis' && <Tennis />}
      {currentPage === 'underconstruction' && <UnderConstructionPage />}
      <div className="App-footer">
        <Footer />
      </div>  
    </div>
    
  );
}

export default App;
