// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-buttons {
  justify-content: flex-end;
}

.button-navbar {
  margin-right: 15px;
}

.text-navbar {
  font-size: 16px;
}

.icon {
  display: none;
}


@media screen and (max-width: 600px) {
  .text {
    display: none;
  }

  .icon {
    display: inline-block;
  }

  .text-navbar {
    font-size: 12px;
  }

}
`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE;IACE,aAAa;EACf;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,eAAe;EACjB;;AAEF","sourcesContent":[".nav-buttons {\n  justify-content: flex-end;\n}\n\n.button-navbar {\n  margin-right: 15px;\n}\n\n.text-navbar {\n  font-size: 16px;\n}\n\n.icon {\n  display: none;\n}\n\n\n@media screen and (max-width: 600px) {\n  .text {\n    display: none;\n  }\n\n  .icon {\n    display: inline-block;\n  }\n\n  .text-navbar {\n    font-size: 12px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
