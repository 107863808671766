import React from "react";
import "./Projects.css";
import { IoIosTennisball } from "react-icons/io";
import { MdConstruction } from "react-icons/md";

const Projects = ({ onPageChange }) => {
  return (
    <div id="Projects">
      <div className="project">
        <button className='button-project' onClick={() => onPageChange('tennis')}>
          <div className="project-content">
            <div className="project-icon">
              <IoIosTennisball className="icon-row" />
            </div>
            <div className="project-divider"></div>
            <div className="project-description">
              <h1>Tennis Result Prediction</h1>
              <p>This is an iterative project where a machine learning model will predict the outcome of a tennis match based on players' statistics.</p>
            </div>
          </div>
        </button>
      </div>
      <div className="project">
        <button className='button-project' onClick={() => onPageChange('underconstruction')}>
          <div className="project-content">
            <div className="project-icon">
              <MdConstruction className="icon-row" />
            </div>
            <div className="project-divider"></div>
            <div className="project-description">
              <h1> On going project</h1>
              <p>More projects are coming soon...</p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Projects;
