const experiences = [
  {
    title : "AI Engineer",
    company : "Nationale Nederlanden - Apr 2024 – Present",
    description : [
      "Generative AI research and development",
      "Deployment of machine learning models",
      "Data engineering and data pipelines",
    ],
  },
  {
    title : "MLOps Engineer",
    company : "Telefonica through Aszendit - Dec 2023 – Apr 2024",
    description : [
      "Assess RAG systems using Large Language Models (LLMs).",
      "Deployment and testing of components and servers on virtual machines ",
      "within internal clusters and cloud environments.",
    ],
  },
  {
    title : "AI Engineer",
    company : "Minsait (Indra) - Jul 2023 – Dec 2023",
    description : [
      "Research and development of machine learning models for predictive maintenance.",
      "Implementation of data pipelines for efficient data ingestion and transformation.",
    ],
  },
  {
    title : "Artificial Intelligence Internship",
    company : "Minsait (Indra) - Jan 2023 – Jul 2023",
    description : [
      "Development of a machine learning model for classification of bird species based on its trajectory.",
      "Apification archetype development and tests automatization.",
      "Computer vision research to enhance outcomes.",
    ],
  },
  {
    title : "C++ Software Development Internship",
    company : "Philotech - Oct 2022 – Jan 2023",
    description : [
      "Software development for an autonomous robot in C++.",
      "Use of OpenCV and ROS libraries under Ubuntu Operating System.",
      "Use of Git version control software.",
    ],
  },
];

const volunteering = [
  {
    title : "Math teacher",
    logo : "./logocaritas.png",
    company : "Caritas",
    description : "Math teacher for people at risk of social exclusion",
  },
  {
    title : "Programming mentor",
    logo : "./Logo_CoderDojo.png",
    company : "CoderDojo",
    description : "Mentor for kids from 6 to 16 years old in programming",
  },
];

const courses = [
  {
    title : "Machine Learning Course",
    company : "Codecademy",
    description :
        "ML concepts and algorithms (linear/logistic regression, decision trees, random forests, SVM, k-nearest neighbors, clustering algorithms such as k-means and hierarchical clustering).",
  },
  {
    title : "Deep Learning Course",
    company : "Udemy",
    description :
        "DL techniques and applications, covering a variety of processing techniques (one-hot encoding, normalization, data augmentation) and modeling algorithms (CNN, RNN, LSTM).",
  },
  {
    title : "Convolutional Neural Networks Course",
    company : "Udemy",
    description : "In-depth exploration of convolutional neural networks",
  },
  {
    title : "Data Science in Python Course",
    company : "Universidad Carlos III de Madrid",
    description :
        "Hands-on course covering data analysis, visualization, and machine learning using Python (pandas, matplotlib, scikit-learn)",
  },
  {
    title : "Docker Course",
    company : "Udemy",
    description : "Docker fundamentals and containerization techniques for software deployment.",
  },
  {
    title : "Azure Fundamentals Course",
    company : "Udemy",
    description : "Azure cloud computing fundamentals and terraform for infrastructure as code.",
  },
];

const education = {
  degree : "Bachelor's degree in ",
  name : "Applied Mathematics & Computing",
  university : "Universidad Carlos III de Madrid - 2019–2023",
  details : "Degree taught entirely in English",
};

const participation = [
  "HungerForInnovation 2022",
  "3º position in Case Study 2020",
  "Volleyball coach",
];

const skillsdata = [
  {
    name : "All",
    progress_bars : [
      {
        name : "C",
        value : 85,
      },
      {
        name : "C++",
        value : 93,
      },
      {
        name : "Python",
        value : 100,
      },
      {
        name : "SQL",
        value : 85,
      },
      {
        name : "Tensorflow",
        value : 92,
      },
      {
        name : "Keras",
        value : 95,
      },
      {
        name : "OpenCV",
        value : 85,
      },
      {
        name : "MLFlow",
        value : 85,
      },
      {
        name : "Docker",
        value : 95,
      },
      {
        name : "Terraform",
        value : 80,
      },
      {
        name : "Azure",
        value : 80,
      },
      {
        name : "AWS",
        value : 50,
      },
      {
        name : "Flask",
        value : 95,
      },
      {
        name : "React",
        value : 95,
      },
    ],
  },
  {
    name : "Languages",
    progress_bars : [
      {
        name : "C++",
        value : 93,
      },
      {
        name : "C",
        value : 85,
      },
      {
        name : "Python",
        value : 100,
      },
      {
        name : "SQL",
        value : 85,
      },
      {
        name : "Matlab",
        value : 90,
      },
    ],
  },
  {
    name : "Python",
    progress_bars : [
      {
        name : "Pandas",
        value : 100,
      },
      {
        name : "Numpy",
        value : 100,
      },
      {
        name : "PySpark",
        value : 100,
      },
      {
        name : "Scikit-learn",
        value : 95,
      },
      {
        name : "Pytorch",
        value : 90,
      },
      {
        name : "Tensorflow",
        value : 95,
      },
      {
        name : "Keras",
        value : 100,
      },
      {
        name : "Matplotlib",
        value : 95,
      },
      {
        name : "Seaborn",
        value : 90,
      },
      {
        name : "OpenCV",
        value : 90,
      },
      {
        name : "Flask",
        value : 95,
      },
      {
        name : "FastAPI",
        value : 85,
      },

    ],
  },
  {
    name : "Tools",
    progress_bars : [
      {
        name : "Docker",
        value : 90,
      },
      {
        name : "Terraform",
        value : 85,
      },
      {
        name : "Azure",
        value : 85,
      },
      {
        name : "AWS",
        value : 50,
      },
      {
        name : "MLFlow",
        value : 85,
      },
    ],
  },
  {
    name : "AI",
    progress_bars : [
      {
        name : "Tensorflow",
        value : 95,
      },
      {
        name : "Keras",
        value : 100,
      },
      {
        name : "Pytorch",
        value : 85,
      },
      {
        name : "OpenCV",
        value : 90,
      },
      {
        name : "Flask",
        value : 95,
      },
      {
        name : "FastAPI",
        value : 85,
      },
      {
        name : "Docker",
        value : 95,
      },
      {
        name : "MLFlow",
        value : 85,
      },
    ],
  },
  {
    name : "Web Development",
    progress_bars : [
      {
        name : "React",
        value : 90,
      },
      {
        name : "Angular",
        value : 70,
      },
      {
        name : "Typescript",
        value : 85,
      },
      {
        name : "Javascript",
        value : 85,
      },
      {
        name : "HTML",
        value : 85,
      },
      {
        name : "CSS",
        value : 85,
      },
      {
        name : "Flask",
        value : 85,
      },
      {
        name : "FastAPI",
        value : 85,
      },
    ],
  },
];

export {experiences,  courses,       education,
        volunteering, participation, skillsdata};
