// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolIcon {
  padding: 5%;
  color: rgb(45, 184, 154);
  height: 100px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  font-size: x-large;
  padding: 20px;
  box-sizing: border-box;
}

[data-theme='light'] .toolIcon {
  color: #aa9e6f;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UnderConstruction/UnderConstruction.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,8CAA8C;EAC9C,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".toolIcon {\n  padding: 5%;\n  color: rgb(45, 184, 154);\n  height: 100px;\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 80vh;\n  font-family: 'Courier New', Courier, monospace;\n  text-align: center;\n  font-size: x-large;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n[data-theme='light'] .toolIcon {\n  color: #aa9e6f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
