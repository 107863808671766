// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

[data-theme='light'] body {
  background-color: #ebeaea;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-footer {
  margin-top: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Arial', sans-serif;\n}\n\n[data-theme='light'] body {\n  background-color: #ebeaea;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.App-footer {\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
