// ResultadoPrediccion.jsx
import React from "react";

const ResultadoPrediccion = ({ resultado }) => {
  console.log("ResultadoPrediccion.jsx", resultado);
  const round_pred = (resultado.pred * 100).toFixed(2);

  return (
    <div className="resultado-prediccion">
      <p>{round_pred}%</p>
    </div>
  );
};

export default ResultadoPrediccion;
