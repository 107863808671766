import React, { useState } from 'react';
import './Buscador_jugadores.css';
import { IoSearchCircleOutline } from "react-icons/io5";
import greyImg from './logos/url_img.png';

const Buscador_jugadores = ({ nombres, photos, onJugadorBuscado }) => {
  const [busqueda, setBusqueda] = useState('');
  const [resultados, setResultados] = useState([]);
  const [nombreBuscado, setNombreBuscado] = useState('');
  const [sugerencias, setSugerencias] = useState([]);
  const [imagenUrl, setImagenUrl] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleBusqueda = (event) => {
    const textoBusqueda = event.target.value;
    setBusqueda(textoBusqueda);

    const resultadosFiltrados = nombres.filter((nombre) =>
      nombre.toLowerCase().includes(textoBusqueda.toLowerCase())
    );

    setResultados(resultadosFiltrados);

    setSugerencias(!resultadosFiltrados.length && textoBusqueda.trim() !== '' ? nombres : []);

    if (!resultadosFiltrados.length) {
      setNombreBuscado('');
      setImagenUrl(false);
    }
  };

  const handleItemClick = (item) => {
    setBusqueda(item);
    setResultados([]);
    setSugerencias([]);
  };

  const handleBuscarClick = () => {
    const jugadorBuscado = photos.find(
      (item) => item.nombre.toLowerCase() === busqueda.toLowerCase()
    );

    if (jugadorBuscado) {
      onJugadorBuscado(jugadorBuscado.nombre);
      setNombreBuscado(jugadorBuscado.nombre);
      if (jugadorBuscado.img != null) {
        setImagenUrl(jugadorBuscado.img);
      } else {
        setImagenUrl(greyImg);
      }
      setSugerencias([]);
    } else {
      console.log('Jugador no encontrado');
      setNombreBuscado('');
      setImagenUrl(false);

      const sugerenciasFiltradas = nombres.filter(
        (nombre) => nombre.toLowerCase().includes(busqueda.toLowerCase())
      );

      setSugerencias(sugerenciasFiltradas);
    }

    setResultados([]);
    setIsFocused(true);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (!document.activeElement.classList.contains('buscador')) {
        setResultados([]);
        setSugerencias([]);
        setNombreBuscado('');
        setImagenUrl(false);
        setIsFocused(false);
      }
    }, 0);
  };

  const handleNombreClick = (nombre, event) => {
    event.stopPropagation();
    setBusqueda(nombre);
    setResultados([]);
    setSugerencias([]);
  };

  return (
    <div className="contenedor">
      <div className="buscador-container">
        <div className="buscador-jugadores" onMouseEnter={() => setIsFocused(true)} onMouseLeave={() => setIsFocused(false)}>
          <button onClick={handleBuscarClick}>
            <IoSearchCircleOutline />
          </button>
          <input
            className="input-jugadores"
            type="text"
            placeholder="Search player"
            value={busqueda}
            onChange={handleBusqueda}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            style={{ width: `${imagenUrl ? 'auto' : '100%'}` }}
          />

          {isFocused && (resultados.length > 0 || sugerencias.length > 0) && (
            <ul className="ul-jugadores">
              {sugerencias.length > 0 ? (
                <div>
                  <p>¿Maybe you meant?</p>
                  <ul className="ul-jugadores-sugeridos">
                    {sugerencias.map((sugerencia, index) => (
                      <li key={index} onClick={() => handleItemClick(sugerencia)} className="nombre-item">
                        {sugerencia}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                resultados.map((nombre, index) => (
                  <li key={index} onClick={() => handleItemClick(nombre)} className="nombre-item">
                    <div onMouseDown={(event) => handleNombreClick(nombre, event)}>
                      {nombre}
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}

          {nombreBuscado && (
            <div style={{ fontSize: '1.5em', marginTop: '10px' }}>
            </div>
          )}
        </div>

        {imagenUrl && (
          <div className='Img-container'>
            <img className="imagen" src={imagenUrl} alt="Foto del jugador" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Buscador_jugadores;
