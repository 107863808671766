import React, { useState } from "react";
import "./Resume.css";
import universityLogo from "./png/Logo_UC3M.png";
import coderdojoLogo from "./png/Logo_CoderDojo.png";
import caritasLogo from "./png/logocaritas.png";
import { experiences, courses, education, volunteering, participation, skillsdata } from "./content";


const Resume = () => {
  const [selectedSection, setSelectedSection] = useState("All");
  return (
    <div className="resume-container">
      {/* <div className="download-resume-button">
        <a href="../../../../public/Resume_Maria_Suevos_2024.pdf" download>
          Download Resume
        </a>
      </div> */}

      <div className="column">
        <div className="Experience section">
          <h2>Experience</h2>
          {experiences.map((experience, i) => (
            <div key={i}>
              <h3>{experience.title}</h3>
              <p>{experience.company}</p>
              <p>{experience.date}</p>
              <ul>
                {experience.description.map((desc, j) => (
                  <li key={j}>{desc}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="Skills section">
          <h2>Skills</h2>
          <p> The following buttons will show the skills known in each section. Click on the button to see the progress bars!</p>
          <div className="skill-buttons">
            <button className={selectedSection === "All" ? "selected" : ""} onClick={() => setSelectedSection("All")}>All</button>
            <button className={selectedSection === "Python" ? "selected" : ""} onClick={() => setSelectedSection("Python")}>Python</button>
            <button className={selectedSection === "Languages" ? "selected" : ""} onClick={() => setSelectedSection("Languages")}>Languages</button>
            <button className={selectedSection === "Tools" ? "selected" : ""} onClick={() => setSelectedSection("Tools")}>Tools</button>
            <button className={selectedSection === "AI" ? "selected" : ""} onClick={() => setSelectedSection("AI")}>AI</button>
          </div>
          {selectedSection && (
            <div className="progress-bars">
              {skillsdata
                .find((section) => section.name === selectedSection)
                .progress_bars.map((bar, index) => (
                  <div key={index} className="progress-bar">
                    <p>{bar.name}</p>
                    <progress value={bar.value} max="100"></progress>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="column">
        <div className="Education section">
          <h2>Education</h2>
          <div className="education-container">
            <img src={universityLogo} alt="University Logo" className="university-logo" />
            <div className="education-info">
              <p>{education.degree}</p>
              <p className="education_name">{education.name}</p>
              <p>{education.university}</p>
              <p>{education.details}</p>
            </div>
          </div>
        </div>
        <div className="Training section">
          <h2>Further Training</h2>
          {courses.map((course, i) => (
            <div key={i} className="course">
              <h3>{course.title}</h3>
              <p>{course.company}</p>
              <ul>
                <li>{course.description}</li>
              </ul>
            </div>
          ))}
        </div>
        <div className="Volunteering section">
          <h2>Volunteering</h2>
          {volunteering.map((activity, i) => (
            <div key={i} className="volunteering-item">
              <div className="volunteering-logo">
                {activity.company === "CoderDojo" ? (
                  <img src={coderdojoLogo} alt="volunteer-logo" className="volunteer-logo" />
                ) : (
                  <img src={caritasLogo} alt="volunteer-logo" className="volunteer-logo" />
                )}
              </div>
              <div className="volunteering-info">
                <p className="volunteering-name">{activity.title}</p>
                <p>{activity.company}</p>
                <p>{activity.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="Languages section">
          <h2>Languages</h2>
          <div className="language">
            <p>Spanish</p>
            <div className="language-buttons">
              <button className="beginner">Beginner</button>
              <button className="intermediate">Intermediate</button>
              <button className="advanced">Advanced</button>
              <button className="native current-level">Native</button>
            </div>
          </div>
          <div className="language">
            <p>English</p>
            <div className="language-buttons">
              <button className="beginner">Beginner</button>
              <button className="intermediate">Intermediate</button>
              <button className="advanced current-level">Advanced</button>
              <button className="native">Native</button>
            </div>
          </div>
        </div>
        <div className="Participation section">
          <h2>Participation</h2>
          <ul>
            {participation.map((event, i) => (
              <li key={i}>
                - {event}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resume;
