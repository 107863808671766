// Tennis.jsx
import React, { useState } from "react";
import BuscadorJugadores from "../../../Components/Tennis/Buscadores/Buscador_jugadores/Buscador_jugadores";
import BuscadorTorneo from "../../../Components/Tennis/Buscadores/Buscador_torneo/Buscador_torneo";
import Calendario from "../../../Components/Tennis/Calendario/Calendario";
import ResultadoPrediccion from "../../../Components/Tennis/ResultadoPrediccion/ResultadoPrediccion";
import listaFotos from "../../../Components/Tennis/Buscadores/db/json/atp_players_photos.json";
import LoadingComponent from "../../../Components/Tennis/Loading/Loading";
import logoDark from "./logos/dark_raquet.png";
import logoGrey from "./logos/light_raquet.png";
import './Tennis.css';
import { useColorMode } from "@chakra-ui/react";

let listaNombresData = listaFotos.map((foto) => foto.nombre);

const Tennis = () => {
  const [jugadorBuscado1, setJugadorBuscado1] = useState(null);
  const [jugadorBuscado2, setJugadorBuscado2] = useState(null);
  const [torneoBuscado, setTorneoBuscado] = useState(null);
  const [rondaSeleccionada, setRondaSeleccionada] = useState(null);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [resultadoPrediccion, setResultadoPrediccion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notReload, setNotReload] = useState(true);
  const { colorMode } = useColorMode();
  const [showResultado, setShowResultado] = useState(false);

  const handleJugadorBuscado1 = (jugadorInfo) => {
    setJugadorBuscado1(jugadorInfo);
    setResultadoPrediccion(false);
    setNotReload(true);
  };

  const handleJugadorBuscado2 = (jugadorInfo) => {
    setJugadorBuscado2(jugadorInfo);
    setResultadoPrediccion(false);
    setNotReload(true);
  };

  const handleTorneoBuscado = (torneoInfo) => {
    setTorneoBuscado(torneoInfo);
    setResultadoPrediccion(false);
    setNotReload(true);
  }

  const handleRondaSeleccionada = (ronda) => {
    setRondaSeleccionada(ronda);
    setResultadoPrediccion(false);
    setNotReload(true);
  }

  const handleFechaSeleccionada = (fecha) => {
    setFechaSeleccionada(fecha);
    setResultadoPrediccion(false);
    setNotReload(true);
  }

  const handleClickPredecir = async () => {
    setNotReload(false);
    setResultadoPrediccion(false);
    setLoading(true);
    console.log('Predecir')
    console.log('fechaSeleccionada', fechaSeleccionada)
    try {
      const response = await fetch('https://api.mariasuevos.com/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jugador1: jugadorBuscado1,
          jugador2: jugadorBuscado2,
          torneo: torneoBuscado,
          ronda: rondaSeleccionada,
          fecha: fechaSeleccionada,
        }),
      });
      console.log('Response:', response);
      if (!response.ok) {
        throw new Error('Error al predecir. Por favor, verifica los datos.');
      }

      const data = await response.json();
      console.log(data);
      setResultadoPrediccion(data);

      setShowResultado(true);
    } catch (error) {
      console.error('Error:', error.message);
    }

    setLoading(false);
  };

  const isSmall = window.innerWidth < 1050;
  return (
    <div className="Tennis">
      <div className="Instructions">
        <p className="Instructions-text">Select the players, tournament, round and date (Make sure to click on the search button). The predict button will show the winner and the probability of winning.</p>
      </div>
      {isSmall ? (
        <div className="small-container">
          <div className="tennis-container">
            <div className={`buscador-left ${resultadoPrediccion && resultadoPrediccion.winner === jugadorBuscado1 ? 'ganador' : ''}`}>
              <BuscadorJugadores nombres={listaNombresData} photos={listaFotos} onJugadorBuscado={handleJugadorBuscado1} />
            </div>
            <div className="big-box">
              <img src={colorMode === "light" ? logoGrey : logoDark} alt="logo" />
              {loading && <LoadingComponent />}
              {showResultado && resultadoPrediccion && (
                <ResultadoPrediccion className="resultado" resultado={resultadoPrediccion} />
              )}
              {jugadorBuscado1 && jugadorBuscado2 && torneoBuscado && rondaSeleccionada && fechaSeleccionada && notReload && (
                <button className="button" onClick={handleClickPredecir}>Predict</button>
              )}
            </div>
            <div className={`buscador-right ${resultadoPrediccion && resultadoPrediccion.winner === jugadorBuscado2 ? 'ganador' : ''}`}>
              <BuscadorJugadores nombres={listaNombresData} photos={listaFotos} onJugadorBuscado={handleJugadorBuscado2} />
            </div>
          </div>
          <div className="componentes-abajo">
            <div className="calendario-wrapper">
              <Calendario onFechaSeleccionada={handleFechaSeleccionada} />
            </div>
            <div className="torneo">
              <BuscadorTorneo onTorneoBuscado={handleTorneoBuscado} onRondaSeleccionada={handleRondaSeleccionada} />
            </div>
          </div>
        </div>
      ) : (
        <div className="tennis-container">
          <div className={`buscador-left ${resultadoPrediccion && resultadoPrediccion.winner === jugadorBuscado1 ? 'ganador' : ''}`}>
            <BuscadorJugadores nombres={listaNombresData} photos={listaFotos} onJugadorBuscado={handleJugadorBuscado1} />
          </div>
          <div className="big-box">
            <img src={colorMode === "light" ? logoGrey : logoDark} alt="logo" />
            <div className="componentes-abajo">
              <div className="calendario-wrapper">
                <Calendario onFechaSeleccionada={handleFechaSeleccionada} />
              </div>
              <div className="torneo">
                <BuscadorTorneo onTorneoBuscado={handleTorneoBuscado} onRondaSeleccionada={handleRondaSeleccionada} />
              </div>
            </div>
            {jugadorBuscado1 && jugadorBuscado2 && torneoBuscado && rondaSeleccionada && fechaSeleccionada && notReload && (
              <button className="button" onClick={handleClickPredecir}>Predecir</button>
            )}
            {loading && <LoadingComponent />}
            {showResultado && resultadoPrediccion && (
              <ResultadoPrediccion className="resultado" resultado={resultadoPrediccion} />
            )}
          </div>
          <div className={`buscador-right ${resultadoPrediccion && resultadoPrediccion.winner === jugadorBuscado2 ? 'ganador' : ''}`}>
            <BuscadorJugadores nombres={listaNombresData} photos={listaFotos} onJugadorBuscado={handleJugadorBuscado2} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tennis;
