// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("logos/yellow_loading_ball.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  position: fixed;
  display: flex;
  height: 100%;
  width: 16vw;
  overflow: hidden;
}

.tennis-ball {
  width: 40px;
  height: 40px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: contain;
  position: relative;
  animation: moveTennisBall 2s linear infinite;
}

.loading-text {
  font-size: 16px;
  color: #7c7979;
  margin-left: 30%;
}

@keyframes moveTennisBall {

  0%,
  100% {
    left: 0;
    transform: translateY(0);
  }

  25%,
  75% {
    left: calc(50% - 40px);
    transform: translateY(30px);
  }

  50% {
    left: calc(100% - 40px);
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Tennis/Loading/Loading.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,2EAAwE;EACxE,wBAAwB;EACxB,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;;EAEE;;IAEE,OAAO;IACP,wBAAwB;EAC1B;;EAEA;;IAEE,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;AACF","sourcesContent":[".loading-container {\n  position: fixed;\n  display: flex;\n  height: 100%;\n  width: 16vw;\n  overflow: hidden;\n}\n\n.tennis-ball {\n  width: 40px;\n  height: 40px;\n  background: url('logos/yellow_loading_ball.png') no-repeat center center;\n  background-size: contain;\n  position: relative;\n  animation: moveTennisBall 2s linear infinite;\n}\n\n.loading-text {\n  font-size: 16px;\n  color: #7c7979;\n  margin-left: 30%;\n}\n\n@keyframes moveTennisBall {\n\n  0%,\n  100% {\n    left: 0;\n    transform: translateY(0);\n  }\n\n  25%,\n  75% {\n    left: calc(50% - 40px);\n    transform: translateY(30px);\n  }\n\n  50% {\n    left: calc(100% - 40px);\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
