import React from "react";
import "./Home.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata } from "./content";
import ProfileImage from "./photo.jpg";

const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <title> María Suevos </title>
        </Helmet>
        <div className="title-container align-items-center">
          <h1 className="fluidz-48 mb-1x">{introdata.title}</h1>
        </div>
        <div className="intro_sec d-flex align-items-center">
          <div
            className="h_bg-image"
            style={{
              backgroundImage: `url(${ProfileImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="text-home">
            <div className="align-self-center ">
              <div className="intro mx-auto">
                <h1 className="animated-text">
                  <Typewriter
                    options={{
                      strings: [
                        // introdata.animated.first,
                        introdata.animated.second,
                        introdata.animated.third,
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 5,
                    }}
                  />
                </h1>
                <p className="text-description">{introdata.description1}</p>
                <p className="text-description">{introdata.description2}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Home;
