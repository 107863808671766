// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendario {
  width: 5vw;
  background-color: rgba(56, 51, 51, 0.7);
  border-radius: 10px;
  justify-self: start;
  overflow-y: auto;
  max-height: 10vh;
}

.calendario:hover {
  cursor: pointer;
}

.calendario:hover option {
  background-color: rgb(56, 51, 51);
  color: #bcced6;
}

[data-theme='light'] .calendario {
  background-color: #DBD4BE;
}

[data-theme='light'] .calendario:hover option {
  background-color: rgb(224, 223, 219);
  color: #311203;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Tennis/Calendario/Calendario.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uCAAuC;EACvC,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,cAAc;AAChB","sourcesContent":[".calendario {\n  width: 5vw;\n  background-color: rgba(56, 51, 51, 0.7);\n  border-radius: 10px;\n  justify-self: start;\n  overflow-y: auto;\n  max-height: 10vh;\n}\n\n.calendario:hover {\n  cursor: pointer;\n}\n\n.calendario:hover option {\n  background-color: rgb(56, 51, 51);\n  color: #bcced6;\n}\n\n[data-theme='light'] .calendario {\n  background-color: #DBD4BE;\n}\n\n[data-theme='light'] .calendario:hover option {\n  background-color: rgb(224, 223, 219);\n  color: #311203;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
