import React from 'react';
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  extendTheme,
  useColorMode,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import "./Navbar.css";
import { HiHome } from "react-icons/hi";
import { GiMaterialsScience } from "react-icons/gi";
import { FiUser } from "react-icons/fi";

const theme = extendTheme({
  colors: {
    button: "#CCC5B0", // #D1CAB4
    navbar: "#DBD4BE",
  },
});

export default function Navbar({ onPageChange }) {
  const { colorMode, toggleColorMode } = useColorMode();

  const buttonColor = useColorModeValue(theme.colors.button, undefined);

  return (
    <Box
      className='Box-navbar'
      bg={useColorModeValue(theme.colors.navbar, "gray.900")}
      px={20}
      width={["100%"]}
    >
      <Flex h={16} className="nav-buttons" alignItems={"center"}>

        <Button className='button-navbar' onClick={() => onPageChange('home')} bg={buttonColor}>
          <a className='text-navbar' href="#Home">
            <HiHome className="icon" />
            <span className="text">Home</span>
          </a>
        </Button>

        <Button className='button-navbar' onClick={() => onPageChange('projects')} bg={buttonColor}>
          <a className='text-navbar' href="#Projects">
            <GiMaterialsScience className="icon" />
            <span className="text">Projects</span>
          </a>
        </Button>

        <Button className='button-navbar' onClick={() => onPageChange('resume')} bg={buttonColor}>
          <a className='text-navbar' href="#Resume">
            <FiUser className="icon" />
            <span className="text">Resume</span>
          </a>
        </Button>

        <Flex alignItems={"center"}>
          <Button onClick={toggleColorMode} bg={buttonColor}>
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
